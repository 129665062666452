/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Apartmán k pronájmu v Bulharsku"}>
        <SiteHeader set="ly5dmp6gibc" currentLanguage={1} />

        <Column className="css-oremi0 --style3 --full pb--20" anim={""} name={"uvod"} animS={"5"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/31ec465de2214c7d9c130eb539f752b4_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
              
              <Title className="title-box fs--86 w--300 swpf--uppercase ls--0 lh--1" content={"APARTMAN In the apartment house RUSALKA"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"informace-1"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
              
              <Title className="title-box fs--62" style={{"maxWidth":614}} content={"<font color=\"#f9f7f7\"><b>Your comfort is paramount to us</b></font>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s8 --center el--3 pb--08 pt--16 flex--center" anim={"2"} animS={"8"} style={{"maxWidth":1300}} columns={"3"}>
            
            <ColumnWrapper anim={""} animS={"2"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box fs--30" content={"<font color=\"#f5f6f8\">Sea and fun within easy reach</font>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":800}} content={"The apartment is located in a quiet area, from where it is a few steps to the sea, the park or the historic center of the town. Children will appreciate the summer attractions."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={""} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box fs--30" content={"<font color=\"#f7f8f9\">Fully equipped</font>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":800}} content={"The kitchen is fully equipped, so you can decide whether to go to one of the many nearby pubs or prepare a relaxing meal at home. Of course there is a washing machine, air conditioning in every room and WI-FI connection"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"2"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box fs--30 pt--16" content={"<font color=\"#f7f7f7\">Transfer from the airport</font>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":800}} content={"Obzor is located about halfway between Varna and Burgas, so it is possible to pick up guests at both airports. Basic transfer price for 4 people 65 Euro, 6-8 people 85 Euro. Possibility of individual arrangement according to the period, number of persons possible."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"informace-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s8 --center el--3 flex--center" anim={"2"} animS={"8"} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39506/415bc64fb8e54e4e91e07544cfdba55f_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" style={{"maxWidth":399}} content={"Pokoj"}>
              </Text>

              <Title className="title-box mt--0" content={"Bedroom 1<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":369}} content={"Bedroom with separate bathroom with bathtub. Separate entrance from the hallway."}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39506/696ea86ea58649ec9afaee9613743949_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" style={{"maxWidth":399}} content={"Pokoj"}>
              </Text>

              <Title className="title-box mt--0" content={"Bedroom 2<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":369}} content={"Bedroom with separate bathroom with shower, separate entrance from the living room."}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/d6060d7b85564073bdfe5ca88fc98857_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/39506/d6060d7b85564073bdfe5ca88fc98857_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/d6060d7b85564073bdfe5ca88fc98857_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/d6060d7b85564073bdfe5ca88fc98857_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/d6060d7b85564073bdfe5ca88fc98857_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" style={{"maxWidth":399}} content={"Pokoj"}>
              </Text>

              <Title className="title-box mt--0" content={"Living room<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":369}} content={"Large room with kitchen, 2 sofas and enough space to accommodate 2 more guest beds."}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-nb0kjx pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/eee5258a4c964cb28f36af2a3b428f2e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
              
              <Title className="title-box fs--62" content={"Book your date"}>
              </Title>

              <Button className="btn-box btn-box--hvr2" content={"Unbinding\nInquiry"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<font color=\"#f9f9fb\">The town of OBZOR</font>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Obzor is a typical holiday resort.  Thanks to its orientation towards tourism, however, you can look forward to the provision of the highest quality services, both in accommodation, catering and entertainment. The town is surrounded by greenery and the sandy beach that stretches from the town's border to the north is also pleasant. The sea is of course clean and pleasantly warm in summer."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Transport</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"If you don't use our transfer or rent a car, you can use public transport, all buses and minibuses on the route Burgas - Varna pass through the horizon. Buses run from the centre of Burgas, in Varna it is a good idea to take public transport from the airport to Atogara Varna and there ask for a bus towards Obzor and Burgas. You can also take a taxi, it doesn't cost much."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<font color=\"#f5f6f9\">Contact</font>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+420 603 524 941<br>krozboud@gmail.com<br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}